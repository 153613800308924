// @import 'primeng/resources/primeng.css';
// @import 'primeicons/primeicons.css';
@import '@ng-select/ng-select/themes/default.theme.css';
// @import 'primeflex/primeflex.css';
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
// @import 'primeng/resources/themes/lara-light-blue/theme.css';
@import '@fortawesome/fontawesome-free/css/all.min.css';

// In Ihrer src/styles.css Datei:
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'ngx-sharebuttons/themes/default';
/* styles.scss */
@import 'leaflet/dist/leaflet.css';
:root {
  --text-color-secondary: rgba(255, 255, 255);
  --wrapper-width: 1491px;
  // --secondary-color: #ffffff; /* Setzt die secondary Farbe auf weiß */
}
.p-button.p-button-secondary.p-button-outlined {
  color: #ffffff;
}
html,
body,
app-root {
  margin: 0;
  height: 100%;
  &:hover a {
    cursor: pointer;
  }
}

app-root {
  display: flex;
  flex-direction: column;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

body,
input,
button,
select,
textarea {
  // font-family: 'Open Sans', sans-serif;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

// header {
//   height: 64px; /* Feste Höhe */
// }

main {
  flex: 1 0 auto; /* Füllt den verfügbaren Platz */
}

footer {
  flex-shrink: 0; /* Verhindert Schrumpfen */
}

*:focus,
.p-focus {
  box-shadow: none !important;
}

p-menubarsub ul {
  gap: 4px;
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}

.wrapper {
  width: var(--wrapper-width);
  max-width: 100%;
  height: 100%;
  margin: auto;
}
.p-editor-container .ql-toolbar {
  background: #f9fafb;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  margin-right: 0 !important;
}
input::placeholder,
textarea::placeholder {
  color: #999 !important;
}

/* Fix für Marker-Icons in Leaflet */
.leaflet-container {
  height: 100%;
  width: 100%;
}

.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block;
}

.leaflet-marker-icon {
  /* Optional: Anpassen der Marker-Icon-Größe */
  width: 25px;
  height: 41px;
}
